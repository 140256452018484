import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Back from "../components/Layout/Back"
import Navigation from "../components/Layout/Navigation"

const DataSecurity = () => (
	<Layout>
		<SEO title="Datenschutz" />
		<Navigation contact={false} />
		<Back color={"#e9c46a"} />
		<div className="h-20"></div>
		<div>
			<div className=" mb-2 lato text-2xl lg:text-4xl text-center tracking-widest text-gray-800">
				Datenschutzerklärung
			</div>
			<div className="h-20"></div>
			<div class="flex justify-center flex-wrap">
				<div class="w-full lg:w-2/3 px-3 lg:px-0 text-sm lg:text-base">
					<div className="text-base lg:text-lg font-semibold my-2">
						Allgemeiner Hinweis und Pflichtinformationen
					</div>
					<div className="text-base lg:text-lg font-semibold my-2">
						Benennung der verantwortlichen Stelle
					</div>
					<div className="text-sm lg:text-base my-2">
						Die verantwortliche Stelle für die Datenverarbeitung auf dieser
						Website ist:
					</div>
					<div className="text-sm lg:text-base my-2">
						<p>Maximilian Zietz</p>
						<p>Hoheluftchausssee 151</p>
						<p>20253 Hamburg</p>
					</div>

					<div className="text-sm lg:text-base my-2">
						<p>Benjamin Becker</p>
						<p>Missundestraße 50</p>
						<p>22769 Hamburg</p>
					</div>
					<div className="text-sm lg:text-base my-2">
						Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
						anderen über die Zwecke und Mittel der Verarbeitung von
						personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
					</div>
					<div className="text-base lg:text-lg font-semibold my-2">
						Widerruf Ihrer Einwilligung zur Datenverarbeitung
					</div>
					<div className="text-sm lg:text-base my-2">
						Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
						Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
						Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
						formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
						Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
					</div>
					<div className="text-base lg:text-lg font-semibold my-2">
						Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
					</div>
					<div className="text-sm lg:text-base my-2">
						Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
						Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
						zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher
						Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem
						sich der Sitz unseres Unternehmens befindet. Der folgende Link
						stellt eine Liste der Datenschutzbeauftragten sowie deren
						Kontaktdaten bereit:
						https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
					</div>
					<div className="text-base lg:text-lg font-semibold my-2">
						Recht auf Datenübertragbarkeit
					</div>
					<div className="text-sm lg:text-base my-2">
						Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
						Einwilligung oder in Erfüllung eines Vertrags automatisiert
						verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
						Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
						die direkte Übertragung der Daten an einen anderen Verantwortlichen
						verlangen, erfolgt dies nur, soweit es technisch machbar ist.
					</div>
					<div className="text-base lg:text-lg font-semibold my-2">
						Recht auf Auskunft, Berichtigung, Sperrung, Löschung
					</div>
					<div className="text-sm lg:text-base my-2">
						Sie haben jederzeit im Rahmen der geltenden gesetzlichen
						Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre
						gespeicherten personenbezogenen Daten, Herkunft der Daten, deren
						Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
						Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und
						auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie
						sich jederzeit über die im Impressum aufgeführten
						Kontaktmöglichkeiten an uns wenden.
					</div>
					<div className="text-base lg:text-lg font-semibold my-2">
						SSL- bzw. TLS-Verschlüsselung
					</div>
					<div className="text-sm lg:text-base my-2">
						Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
						Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
						Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
						über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
						erkennen eine verschlüsselte Verbindung an der „https://“
						Adresszeile Ihres Browsers und am Schloss-Symbol in der
						Browserzeile.
					</div>
					<div className="text-base lg:text-lg font-semibold mt-2">
						Server-Log-Dateien
					</div>
					<div className="text-sm lg:text-base my-2">
						In Server-Log-Dateien erhebt und speichert der Provider der Website
						automatisch Informationen, die Ihr Browser automatisch an uns
						übermittelt. Dies sind:
					</div>
					<ul className="list-disc list-inside">
						<li>Besuchte Seite auf unserer Domain</li>
						<li>Datum und Uhrzeit der Serveranfrage</li>
						<li>Browsertyp und Browserversion</li>
						<li>Verwendetes Betriebssystem</li>
						<li>Referrer URL</li>
						<li>Hostname des zugreifenden Rechners</li>
						<li>IP-Adresse</li>
					</ul>
					<div className="text-sm lg:text-base my-2">
						Es findet keine Zusammenführung dieser Daten mit anderen
						Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6
						Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung
						eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
					</div>
					<div className="text-base lg:text-lg font-semibold my-2">
						Google Web Fonts
					</div>
					<div className="text-sm lg:text-base my-2">
						Unsere Website verwendet Web Fonts von Google. Anbieter ist die
						Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
						USA. Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die
						von uns gewünschte Darstellung unserer Website zu präsentieren,
						unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen.
						Dies erfolgt über den Abruf der Google Web Fonts von einem Server
						von Google in den USA und der damit verbundenen Weitergabe Ihre
						Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche
						Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts
						erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber
						dieser Website haben wir ein berechtigtes Interesse an der optimalen
						Darstellung und Übertragung unseres Webauftritts. Das Unternehmen
						Google ist für das us-europäische Datenschutzübereinkommen "Privacy
						Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die
						Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.
						Einzelheiten über Google Web Fonts finden Sie unter:
						https://www.google.com/fonts#AboutPlace:about und weitere
						Informationen in den Datenschutzbestimmungen von Google:
						https://policies.google.com/privacy/partners?hl=de 
					<div className="text-sm lg:text-base my-2">
						Quelle:
						Datenschutz-Konfigurator von mein-datenschutzbeauftragter.de
					</div>

					</div>
				</div>
			</div>
		</div>
		<div className="h-20"></div>
	</Layout>
)

export default DataSecurity
